import React from 'react';

// import ArticlePreview from "components/help/helpArticlePreview";
import Category from 'components/help/category';

import { getStarted } from 'data/helpArticles';

// const IntroSection = (
//   <p style={{ textAlign: "center", marginBottom: "3rem" }}>
//     Check how to install LiveSession on your website{" "}
//     <a href="/help/how-to-install-livesession-script">here</a> or find guide for
//     your favorite platform below.
//   </p>
// );

export default () => (
  <Category
    title="Get started"
    subtitle="See how to install LiveSession in several ways and get started."
    integrations
    articles={getStarted}
    url="/help/get-started/"
    description="Let's start with install LiveSesssion. You can install script in several ways."
  />
);
